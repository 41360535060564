import React, { useRef, useMemo } from "react";

import { useFrame } from "@react-three/fiber";

import { Edges } from "@react-three/drei";

import { motion } from "framer-motion-3d";

import * as Curves from 'three/addons/curves/CurveExtras.js';

import * as THREE from "three";

const LINE_NB_POINTS = 1200;

const Curve = (props) => {

    const {
        position,
        scale,
        color,
        artworkState,
        setArtworkState,
        perc3,
        numLoops,
        innerRadius,
        outerRadius,
        zDepth,
        spinSpeed,
        rockSpeed,
        accord
    } = props;



    const meshRef = useRef();
    const materialRef = useRef();

    const genPoints = () => {

        let points = [];
        let zWave = 0;

        for (var i = 0; i < 2 * Math.PI; i += 0.01) {

            var x = outerRadius * (Math.sin(i) + innerRadius * Math.sin(numLoops * i));
            var y = outerRadius * (Math.cos(i) - innerRadius * Math.cos(numLoops * i));
            var z = (Math.cos(i) * Math.cos(i));

            var point = new THREE.Vector3(x, y, z);
            points.push(point);
        };

        return new THREE.CatmullRomCurve3(points);

    };

    const curve = useMemo(genPoints, [numLoops, zDepth]);

    const linePoints = useMemo(() => {
        return curve.getPoints(LINE_NB_POINTS);
    }, [curve]);

    const shape = useMemo(() => {

        const shape = new THREE.Shape();
        shape.moveTo(0, -1);
        shape.lineTo(0, 1);
        return shape;

    }, [curve]);

    useFrame(({ clock }) => {
        if (meshRef?.current && materialRef?.current) {
            if (artworkState !== "dormant") {
                if (perc3 < 20) meshRef.current.rotation.z = Math.sin(clock.elapsedTime * rockSpeed) * Math.PI;
                else meshRef.current.rotation.z -= spinSpeed;
                materialRef.current.color.lerp(new THREE.Color(color), 0.02);
                // console.log(Math.sin(clock.elapsedTime) * Math.PI);
            } else {
                materialRef.current.color.lerp(new THREE.Color('grey'), 0.02);
            };
        };
    });

    const variants = {
        normal: {
            x: 0,
            z: 0,
            scale: 1,
            transition: {
                duration: 1.5,
                type: "spring",
                ease: "easeInOut",
                delay: 0.5,
            },
        },
        dormant: {
            scale: 0,
            transition: {
                duration: 1.3,
                ease: "easeInOut",
            },
        },
        accordion: {
            z: [0, 10 * accord, -accord * 0.1, 0],
            scale: 0.95,
            transition: {
                duration: 1.3,
                ease: "easeInOut",
            },
            transitionEnd: () => {setArtworkState('normal')}
        },
    };

    return (
        <motion.group
            position={position}
            initial={{ scale: 0 }}
            animate={{
                scale: scale * 0.1,
                transition: {
                    duration: 0.5,
                    ease: 'easeInOut'
                }
            }}
        >
            <motion.mesh
                ref={meshRef}
                animate={artworkState}
                variants={variants}
            >
                <extrudeGeometry
                    args={[
                        shape,
                        {
                            steps: LINE_NB_POINTS,
                            bevelEnabled: false,
                            extrudePath: curve,
                        },
                    ]}
                />
                <motion.meshStandardMaterial
                    ref={materialRef}
                    transparent
                    opacity={0.4}
                    side={THREE.DoubleSide}
                />
            </motion.mesh>
        </motion.group>
    );
};

export default Curve;
