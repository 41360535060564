import React, { useEffect, useState, useRef } from "react";

import { motion, AnimatePresence } from "framer-motion";

import harryStyles from "./GUI.module.css";

const events_to_listen_to = ["mousedown", "touchstart", "keydown"];

export default function GUI(props) {
    const { wisdom, value, divisibleBy, rootRef, visibleTimeout } = props;

    const timeoutRef = useRef(null);

    const [visible, setVisible] = useState(false);

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    const handleGUIVisible = () => {
        if (timeoutRef?.current) window.clearTimeout(timeoutRef.current);

        setVisible(true);

        timeoutRef.current = window.setTimeout(
            () => setVisible(false),
            visibleTimeout
        );
    };

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    useEffect(() => {
        if (rootRef?.current) {
            let elemToCleanUp = rootRef.current;

            for (const event of events_to_listen_to) {
                elemToCleanUp.addEventListener(event, handleGUIVisible);
            }

            return () => {
                for (const event of events_to_listen_to) {
                    elemToCleanUp.removeEventListener(event, handleGUIVisible);
                }
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        handleGUIVisible();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const variants = {
        showL: {
            x: ["10%", "0%"],
            y: ["15%", "0%"],
            opacity: 1,
            transition: {
                duration: 0.5,
                type: "spring",
                ease: "easeOut",
            },
        },
        showR: {
            x: ["-10%", "0%"],
            y: ["-15%", "0%"],
            opacity: 1,
            transition: {
                duration: 0.5,
                type: "spring",
                ease: "easeOut",
            },
        },
        showGrad: {
            x: "0%",
            y: "0%",
            opacity: 1,
            transition: {
                duration: 1,
                delay: 0.5,
                ease: "easeOut",
            },
        },
        hide: {
            x: "0%",
            y: "0%",
            opacity: 0,
            transition: {
                duration: 1.5,
                ease: "easeOut",
            },
        },
    };

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    return (
        <div id={harryStyles["GUI"]}>
            <motion.div
                id={harryStyles["GUIGrad"]}
                animate={visible ? "showGrad" : "hide"}
                variants={variants}
            >
                <div></div>
            </motion.div>

            <motion.div
                id={harryStyles["wisdomTextWrap"]}
                animate={visible ? "showL" : "hide"}
                variants={variants}
            >
                <div id={harryStyles["wisdomText"]}>
                    <p>{wisdom}</p>
                </div>
            </motion.div>

            <motion.div
                id={harryStyles["valueTrackerWrap"]}
                animate={visible ? "showR" : "hide"}
                variants={variants}
            >
                <div id={harryStyles["valueTracker"]}>
                    <div id={harryStyles["valueGhost"]}>
                        {[...Array(divisibleBy).keys()].map((x, i) => (
                            <div
                                key={i}
                                style={{
                                    height: ((i + 1) / divisibleBy) * 100 + "%",
                                    width: 100 / divisibleBy - 3 + "%",
                                }}
                            ></div>
                        ))}
                    </div>

                    <AnimatePresence>
                        {value &&
                            [
                                ...Array(
                                    Math.floor((value / 100) * divisibleBy)
                                ).keys(),
                            ].map((elem, i, arr) => {
                                return (
                                    <motion.div
                                        key={"value_tracker" + i}
                                        initial={{ opacity: 0 }}
                                        animate={{
                                            opacity: [0, 1, 0.5, 1, 0.5, 1],
                                            transition: {
                                                delay: (i + 1) * 0.2,
                                            },
                                        }}
                                        exit={{
                                            opacity: 0,
                                            transition: {
                                                delay: (divisibleBy - i) * 0.2,
                                            },
                                        }}
                                        style={{
                                            height: `${
                                                ((i + 1) / divisibleBy) * 100
                                            }%`,
                                            width: 100 / divisibleBy - 3 + "%",
                                        }}
                                    ></motion.div>
                                );
                            })}
                    </AnimatePresence>
                </div>
            </motion.div>
        </div>
    );
}
