import React, { useContext, useRef, useEffect } from "react";

import { useFrame } from "@react-three/fiber";

import { useProgress } from "@react-three/drei";

import { motion } from "framer-motion-3d";

import { useAnimate, useMotionValue } from "framer-motion";

import * as THREE from "three";

import Cone from "./Cone";
import Curve from "./Curve";
import Shape from "./Shape";
import { ColorContext, HideObjetContext } from "../Wisdom";

const Objet = (props) => {
    const {
        setLoadingStatus,
        returnProgress,
        middleTorus,
        curve,
        perc3,
        artworkState,
        setArtworkState,
        baseModifiers,
        modifiers,
    } = props;

    const colorValues = useContext(ColorContext);
    const { objetIsHidden, setObjetIsHidden, shouldFloat } =
        useContext(HideObjetContext);
    const { hsl1, hsl2, hsl3 } = colorValues;

    const ref = useRef();
    const float = useRef();

    const { active, loaded, total } = useProgress();

    if (returnProgress) {
        setLoadingStatus({
            // active is a weird value that straight away reverts to false, when loaded = 1 and total = 1, assuming that the progress handler doesnt know how many items will load
            // so there will always be more that 3 items that need loading, so wait until after 3 have loaded and then use the value
            active: loaded > 3 ? active : true,
            loaded,
            total,
        });
    }

    const [scope, animate] = useAnimate();
    const speed = useMotionValue(0);
    const rotationIntensity = useMotionValue(0);
    const floatIntensity = useMotionValue(0);
    const floatingRangeStart = useMotionValue(0);
    const floatingRangeEnd = useMotionValue(0);

    useFrame(({ clock }) => {
        if (float?.current && shouldFloat) {
            float.current.position.y =
                Math.sin(clock.elapsedTime) * speed.current;
            float.current.position.x =
                Math.sin(clock.elapsedTime) * (speed.current * 0.5);
            float.current.position.z =
                Math.sin(clock.elapsedTime) * (speed.current * 0.3);
            float.current.rotation.y =
                0.5 *
                (Math.sin(clock.elapsedTime) *
                    (rotationIntensity.current * 0.3));
            float.current.rotation.x =
                0.5 *
                (Math.sin(clock.elapsedTime) *
                    (rotationIntensity.current * 0.3));
        }
    });

    useEffect(() => {
        const speedAnim = animate(
            speed,
            baseModifiers.float_speed * (modifiers ? modifiers.fsMod : 1),
            {
                duration: 1,
                ease: "easeInOut",
            }
        );

        const rotationIntensityAnim = animate(
            rotationIntensity,
            baseModifiers.float_rotation_intensity *
                (modifiers ? modifiers.friMod : 1),
            {
                duration: 1,
                ease: "easeInOut",
            }
        );

        const floatIntensityAnim = animate(
            floatIntensity,
            baseModifiers.float_intensity * (modifiers ? modifiers.fiMod : 1),
            {
                duration: 1,
                ease: "easeInOut",
            }
        );

        const floatingRangeStartAnim = animate(
            floatingRangeStart,
            baseModifiers.float_range_start * (modifiers ? modifiers.frMod : 1),
            {
                duration: 1,
                ease: "easeInOut",
            }
        );

        const floatingRangeEndAnim = animate(
            floatingRangeEnd,
            baseModifiers.float_range_end * (modifiers ? modifiers.frMod : 1),
            {
                duration: 1,
                ease: "easeInOut",
            }
        );

        return (
            speedAnim.stop,
            rotationIntensityAnim.stop,
            floatIntensityAnim.stop,
            floatingRangeStartAnim.stop,
            floatingRangeEndAnim.stop
        );
    }, [baseModifiers, modifiers]);

    const variants = {
        normal: {
            rotateX: 0,
            rotateY: ref?.current?.rotation.y,
            rotateZ: ref?.current?.rotation.z,
            scale: 1,
            transition: {
                duration: 2,
                type: "spring",
                ease: "easeInOut",
            },
        },
        dormant: {
            rotateX: Math.PI * 0.3,
            rotateY: ref?.current?.rotation.y,
            rotateZ: ref?.current?.rotation.z,
            scale: 1.2,
            transition: {
                duration: 3,
                type: "spring",
                ease: "easeInOut",
            },
        },
        accordion: {
            rotateX: ref?.current?.rotation.x,
            rotateY: ref?.current?.rotation.y,
            rotateZ: ref?.current?.rotation.z,
            scale: 1,
            transition: {
                duration: 2,
                type: "spring",
                ease: "easeInOut",
            },
        },
        happy_spin: {
            rotateX: ref?.current?.rotation.x,
            rotateY: ref?.current?.rotation.y + Math.PI * 2,
            rotateZ: ref?.current?.rotation.z,
            scale: 1,
            transition: {
                duration: 1,
                type: "spring",
                ease: "easeInOut",
            },
            transitionEnd: () => {
                setArtworkState("normal");
            },
        },
        collect_funds: {
            rotateX: [
                ref?.current?.rotation.x,
                ref?.current?.rotation.x + Math.PI * -0.3,
                ref?.current?.rotation.x + Math.PI * -2,
            ],
            rotateY: ref?.current?.rotation.y,
            rotateZ: ref?.current?.rotation.z + Math.PI * -10,
            scale: [1, 1, 1, 2, 2, 2, 1],
            transition: {
                duration: 4.5,
                ease: "easeInOut",
            },
            transitionEnd: () => {
                // console.log("end anim collect_funds");
                setArtworkState("normal");
            },
        },
    };

    return (
        <motion.group ref={float}>
            <motion.group
                ref={ref}
                position={[0, 0, 0]}
                animate={artworkState}
                variants={variants}
                // whileHover={
                //     artworkState !== "normal"
                //         ? null
                //         : {
                //               x: [
                //                   0, -0.2, -0.2, 0, 0, 0, -0.2, -0.2, 0, 0.2,
                //                   0.2, 0, 0, 0, 0.2, 0.2, 0,
                //               ],
                //               y: [
                //                   0, 0, 0.2, 0.2, 0, -0.2, -0.2, 0, 0, 0, -0.2,
                //                   -0.2, 0, 0.2, 0.2, 0, 0,
                //               ],
                //               scale: [1, 1.05, 1],
                //               transition: {
                //                   duration: 1.1,
                //                   ease: "easeInOut",
                //                   repeat: Infinity,
                //               },
                //           }
                // }
            >
                {/* <Cone
                    // cone
                    position={[
                        0, 0, -1.8,
                        // baseModifiers.cone_z_pos * (modifiers ? modifiers.sMod : 1),
                    ]}
                    scale={
                        baseModifiers.cone_scale * (modifiers ? modifiers.sMod : 1)
                    }
                    settings={[4, 1, 100, 1, "true"]}
                    color={hsl3}
                    opacity={0.8}
                    move={false}
                    wireframe={false}
                    accord={
                        baseModifiers.cone_accord *
                        (modifiers ? modifiers.ssMod : 1)
                    }
                    artworkState={artworkState}
                    setArtworkState={setArtworkState}
                /> */}
                <Shape
                    // torus_1
                    hsl1={hsl1}
                    materialMods={{
                        roughness: 0.6,
                        thickness: 2,
                        transmission: 0.8,
                        clearcoatRoughness: 1,
                        clearcoat: 1,
                    }}
                    color={hsl1.getRGB(new THREE.Color())}
                    index={1}
                    position={[
                        0, 0, 0.5,
                        // baseModifiers.torus_1_z_pos *
                        //     (modifiers ? modifiers.ssMod : 1),
                    ]}
                    opacity={1}
                    opacityAnimBase={
                        baseModifiers.torus_1_opacity_base *
                        (modifiers ? modifiers.ssMod : 1)
                    }
                    opacityAnimSpeed={
                        baseModifiers.torus_1_opacity_speed *
                        (modifiers ? modifiers.ssMod : 1)
                    }
                    scale={
                        baseModifiers.torus_1_scale *
                        (modifiers ? modifiers.sMod : 1)
                    }
                    spin={
                        baseModifiers.torus_1_spin *
                        (modifiers ? modifiers.fsMod : 1)
                    }
                    accord={
                        baseModifiers.torus_1_accord *
                        (modifiers ? modifiers.ssMod : 1)
                    }
                    artworkState={artworkState}
                    perc3={perc3}
                />
                {middleTorus && (
                    <Shape
                        // torus_2
                        materialMods={{
                            roughness: 0.6,
                            thickness: 2,
                            transmission: 0.8,
                            clearcoatRoughness: 1,
                            clearcoat: 1,
                        }}
                        color={hsl2.getRGB(new THREE.Color())}
                        index={2}
                        position={[
                            0, 0, -1,
                            // baseModifiers.torus_2_z_pos *
                            //     (modifiers ? modifiers.ssMod : 1),
                        ]}
                        opacity={1}
                        opacityAnimBase={
                            baseModifiers.torus_2_opacity_base *
                            (modifiers ? modifiers.ssMod : 1)
                        }
                        opacityAnimSpeed={
                            baseModifiers.torus_2_opacity_speed *
                            (modifiers ? modifiers.ssMod : 1)
                        }
                        scale={
                            baseModifiers.torus_2_scale *
                            (modifiers ? modifiers.sMod : 1)
                        }
                        spin={
                            baseModifiers.torus_2_spin *
                            (modifiers ? modifiers.ssMod : 1)
                        }
                        accord={
                            baseModifiers.torus_2_accord *
                            (modifiers ? modifiers.ssMod : 1)
                        }
                        artworkState={artworkState}
                        perc3={perc3}
                    />
                )}
                <Shape
                    // torus_3
                    color={hsl3.getRGB(new THREE.Color())}
                    index={3}
                    position={[
                        0, 0, -1.5,
                        // baseModifiers.torus_3_z_pos *
                        //     (modifiers ? modifiers.ssMod : 1),
                    ]}
                    opacity={1}
                    opacityAnimBase={
                        baseModifiers.torus_3_opacity_base *
                        (modifiers ? modifiers.ssMod : 1)
                    }
                    opacityAnimSpeed={
                        baseModifiers.torus_3_opacity_speed *
                        (modifiers ? modifiers.ssMod : 1)
                    }
                    scale={
                        baseModifiers.torus_3_scale *
                        (modifiers ? modifiers.sMod : 1)
                    }
                    spin={
                        baseModifiers.torus_3_spin *
                        (modifiers ? modifiers.ssMod : 1)
                    }
                    accord={
                        baseModifiers.torus_3_accord *
                        (modifiers ? modifiers.ssMod : 1)
                    }
                    artworkState={artworkState}
                    perc3={perc3}
                />
                {/* {curve && */}
                <Curve
                    position={[
                        0, 0, -1.5,
                        // baseModifiers.cone_z_pos * (modifiers ? modifiers.sMod : 1),
                    ]}
                    scale={0.3}
                    color={hsl3.getRGB(new THREE.Color())}
                    artworkState={artworkState}
                    setArtworkState={setArtworkState}
                    perc3={perc3}
                    numLoops={Math.floor(
                        baseModifiers.curve_num_loops *
                            (modifiers ? modifiers.cnlMod : 1)
                    )}
                    innerRadius={
                        baseModifiers.curve_inner_radius *
                        (modifiers ? modifiers.cirMod : 1)
                    }
                    outerRadius={
                        baseModifiers.curve_outer_radius *
                        (modifiers ? modifiers.corMod : 1)
                    }
                    zDepth={
                        baseModifiers.curve_z_depth *
                        (modifiers ? modifiers.czdMod : 1)
                    }
                    spinSpeed={
                        baseModifiers.curve_spin_speed *
                        (modifiers ? modifiers.cssMod : 1)
                    }
                    rockSpeed={
                        baseModifiers.curve_rock_speed *
                        (modifiers ? modifiers.crsMod : 1)
                    }
                    accord={
                        baseModifiers.cone_accord *
                        (modifiers ? modifiers.ssMod : 1)
                    }
                />
                {/* } */}
            </motion.group>
        </motion.group>
    );
};

export default Objet;
