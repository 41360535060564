import React, {
    useContext,
    useState,
    useRef,
    useEffect,
    RefObject,
} from "react"; // we need this to make JSX compile
import FadeIn from "react-fade-in";
import Link from "next/link";
import { useRouter } from "next/router";
import { FaDiscord, FaTwitter } from "react-icons/fa";
import { BreakpointContext } from "pages/_app";
import { BalanceIndicator } from "components/BalanceIndicator";
import { CustomConnectButton } from "components/CustomConnectButton";
import { useAccount } from "wagmi";

export const Header: React.FC = () => {
    const breakpoint = useContext(BreakpointContext);
    const [iconSrc, setIconSrc] = useState("/images/wisdoms-header.png");
    const router = useRouter();
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [exploreMenuIsOpen, setExploreMenuIsOpen] = useState(false);
    const { address } = useAccount();

    useEffect(() => {
        router.events.on("routeChangeStart", () => setMenuIsOpen(false));
        router.events.on("routeChangeStart", () => setExploreMenuIsOpen(false));

        return () => {
            router.events.off("routeChangeStart", () => setMenuIsOpen(false));
            router.events.off("routeChangeStart", () =>
                setExploreMenuIsOpen(false)
            );
        };
    }, [router]);

    function useOutsideAlerter(ref: RefObject<HTMLDivElement>) {
        useEffect(() => {
            /**
             * Close the menus if clicked on outside of element
             */
            function handleClickOutside(event: MouseEvent) {
                if (
                    ref.current &&
                    !ref.current.contains(event.target as Node)
                ) {
                    setMenuIsOpen(false);
                    setExploreMenuIsOpen(false);
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    const openSeaLink: string =
        process.env.NEXT_PUBLIC_ENABLE_TESTNETS === "true"
            ? "https://testnets.opensea.io/collection/wisdom-vessels-3"
            : `https://opensea.io/collection/wisdom-vessels`;

    const etherscanLink = `https://${
        process.env.NEXT_PUBLIC_ENABLE_TESTNETS === "true" ? "goerli." : ""
    }etherscan.io/address/${process.env.NEXT_PUBLIC_COLLECTION_ADDRESS}`;

    useEffect(() => {
        if (breakpoint >= 3) {
            setIconSrc("/images/wisdoms-header.png");
        } else setIconSrc("/images/keiken_icon.png");
    }, [breakpoint]);

    const LinkToMint = () => {
        return (
            <Link href="/mint">
                <div className="py-2 px-4 cursor-pointer flex flex-row gap-2 items-center rounded-3xl p-8 bg-opacity-50 backdrop-blur-md bg-white">
                    Mint
                </div>
            </Link>
        );
    };

    const ExploreButtonLarge = () => {
        return (
            <div onClick={() => setExploreMenuIsOpen(!exploreMenuIsOpen)}>
                <div className="py-2 px-4 cursor-pointer flex flex-row gap-2 items-center rounded-3xl bg-white bg-opacity-50 p-8 bg-blur-md backdrop-blur-sm">
                    <p>Explore ↓</p>
                </div>
            </div>
        );
    };

    const ExploreButtonSmall = () => {
        return (
            <div onClick={() => setMenuIsOpen(!menuIsOpen)}>
                <div className="py-2 px-4 cursor-pointer flex flex-row gap-2 items-center rounded-3xl bg-white bg-opacity-50 p-8 bg-blur-md backdrop-blur-sm">
                    <p>Explore ↓</p>
                </div>
            </div>
        );
    };

    if (breakpoint >= 3) {
        return (
            <div className="fixed inset-x-0 container mx-auto mt-4 md:mx-auto flex justify-start items-center z-50 gap-2 md:gap-4">
                <div className="absolute w-full">
                    <div className="flex w-full justify-center items-center">
                        <Link href="/" className="cursor-pointer">
                            <img
                                src={iconSrc}
                                className="max-h-10"
                                alt="wisdoms"
                            />
                        </Link>
                    </div>
                </div>
                <div
                    className={`bg-opacity-0 px-0 flex flex-row items-center h-full w-full rounded-full py-4`}
                >
                    <div className="flex flex-row w-full justify-between align-center">
                        <div className="flex flex-row gap-2 items-center">
                            <LinkToMint />
                            <ExploreButtonLarge />
                        </div>

                        <div className="flex flex-row gap-2 items-center">
                            {address && (
                                <FadeIn>
                                    <BalanceIndicator />
                                </FadeIn>
                            )}

                            <CustomConnectButton
                                customStyle={
                                    "py-2 px-4 rounded-full flex items-center px-4 cursor-pointer hover:bg-opacity-60"
                                }
                                isInHeader={true}
                            />
                        </div>
                    </div>
                </div>
                {exploreMenuIsOpen && (
                    <div
                        ref={wrapperRef}
                        className="backdrop-blur-md bg-white bg-opacity-50 absolute items-left flex flex-col gap-4 rounded-xl flex-1 self-center left-[72px] p-4 top-16"
                    >
                        <div className="relative z-50 flex flex-col gap-2 justify-left items-start">
                            <p
                                className={`${
                                    router.pathname.startsWith("/marketplace")
                                        ? "underline"
                                        : ""
                                } cursor-pointer hover:underline drop-shadow-lg   text-center`}
                            >
                                <Link href="/marketplace">Marketplace</Link>
                            </p>
                            <p
                                className={`${
                                    router.pathname.startsWith("/vessels")
                                        ? "underline"
                                        : ""
                                } cursor-pointer hover:underline drop-shadow-lg   text-center`}
                            >
                                <Link href="/vessels">Vessels</Link>
                            </p>
                            <p
                                className={`${
                                    router.pathname.startsWith("/seeds")
                                        ? "underline"
                                        : ""
                                } cursor-pointer hover:underline drop-shadow-lg   text-center`}
                            >
                                <Link href="/seeds">Seeds</Link>
                            </p>
                            <p
                                className={`cursor-pointer hover:underline drop-shadow-lg  `}
                            >
                                <a
                                    target="_blank"
                                    rel="noReferrer"
                                    href="https://mirror.xyz/neknel.eth"
                                >
                                    About
                                </a>
                            </p>
                            <div className="flex gap-2 items-center">
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://twitter.com/neknelworld"
                                >
                                    <FaTwitter
                                        className="h-5 w-5 cursor-pointer"
                                        color="black"
                                    />
                                </a>
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://discord.gg/XjznmpyVAc"
                                >
                                    <FaDiscord
                                        className="h-5 w-5 cursor-pointer"
                                        color="black"
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    } else
        return (
            <div className="fixed inset-x-2 mt-4 md:mx-auto flex justify-start items-center z-50 gap-2 md:gap-4 h-14 ">
                <div className="justify-between bg-opacity-30 flex flex-row items-center h-full w-full rounded-full p-4">
                    <div className={"flex flex-row gap-4 items-center"}>
                        <LinkToMint />
                    </div>
                    <ExploreButtonSmall />
                </div>
                {menuIsOpen && (
                    <div
                        ref={wrapperRef}
                        className="bg-opacity-50 backdrop-blur-md bg-white absolute flex flex-col gap-4 rounded-xl flex-1 self-center right-4 p-6 top-16 z-50"
                    >
                        <div className="relative z-50 flex flex-col gap-2 items-center ">
                            <CustomConnectButton customStyle="px-4 py-2 rounded-full h-14 border bg-opacity-0 border-black"></CustomConnectButton>
                            {address && <BalanceIndicator />}
                            <hr className="w-full my-2 border-black"></hr>
                            <div className={"flex flex-col gap-4 items-center"}>
                                <p
                                    className={`${
                                        router.pathname.startsWith("/mint")
                                            ? "underline"
                                            : ""
                                    } cursor-pointer hover:underline drop-shadow-lg   text-center`}
                                >
                                    <Link href="/mint">Mint</Link>
                                </p>
                                <p
                                    className={`${
                                        router.pathname.startsWith(
                                            "/marketplace"
                                        )
                                            ? "underline"
                                            : ""
                                    } cursor-pointer hover:underline drop-shadow-lg   text-center`}
                                >
                                    <Link href="/marketplace">Marketplace</Link>
                                </p>
                                <p
                                    className={`${
                                        router.pathname.startsWith("/vessels")
                                            ? "underline"
                                            : ""
                                    } cursor-pointer hover:underline drop-shadow-lg   text-center`}
                                >
                                    <Link href="/vessels">Vessels</Link>
                                </p>

                                <p
                                    className={`${
                                        router.pathname.startsWith("/seeds")
                                            ? "underline"
                                            : ""
                                    } cursor-pointer hover:underline drop-shadow-lg   text-center`}
                                >
                                    <Link href="/seeds">Seeds</Link>
                                </p>
                                <p
                                    className={`cursor-pointer hover:underline drop-shadow-lg  `}
                                >
                                    <a
                                        target="_blank"
                                        rel="noReferrer"
                                        href="https://mirror.xyz/neknel.eth"
                                    >
                                        About
                                    </a>
                                </p>
                            </div>
                            <hr></hr>
                            <div className="flex gap-4 items-center">
                                <a href="https://twitter.com/neknelworld">
                                    <FaTwitter
                                        className="h-8 w-8 cursor-pointer"
                                        color="black"
                                    />
                                </a>
                                <a href="https://discord.gg/XjznmpyVAc">
                                    <FaDiscord
                                        className="h-8 w-8 cursor-pointer"
                                        color="black"
                                    />
                                </a>
                            </div>
                            <hr></hr>

                            <div className="flex gap-4 items-center">
                                <a
                                    href={openSeaLink}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img
                                        src="/icons/opensea-black.svg"
                                        className="w-8 h-8 cursor-pointer"
                                        alt="Link to the Wisdom collection on OpenSea"
                                    />
                                </a>
                                <a
                                    href={etherscanLink}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img
                                        src="/icons/etherscan-black.svg"
                                        className="w-8 h-8 cursor-pointer"
                                        alt="Link to the Wisdom collection on Etherscan"
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
};
