import { twMerge } from "tailwind-merge";

type RoundedBoxProps = {
    header?: string;
    body?: string;
    children: string | JSX.Element | JSX.Element[] | (() => JSX.Element) | any;
    customStyle?: string | boolean;
};

export const RoundedBox: React.FC<RoundedBoxProps> = ({
    header,
    body,
    children,
    customStyle,
}): React.ReactElement => {
    return (
        <div
            className={twMerge(
                "rounded-3xl bg-white bg-opacity-50 backdrop-blur-md p-8",
                `${customStyle}`
            )}
        >
            {children}
        </div>
    );
};
