import React from "react";

import {
    EffectComposer,
    Bloom,
    DepthOfField,
} from "@react-three/postprocessing";

const Effects = () => {

    return (
        <EffectComposer>
            <DepthOfField
                focusDistance={0}
                focalLength={0.5}
                bokehScale={2}
                height={480}
            />
            <Bloom
                luminanceThreshold={0}
                luminanceSmoothing={0.7}
                intensity={2}
                height={350}
            />
        </EffectComposer>
    );
};

export default Effects;
