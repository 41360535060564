import React, { useRef, useEffect, useState, useContext } from "react";

import { useFrame, useLoader } from "@react-three/fiber";

import { Edges } from "@react-three/drei";

import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";

import { motion } from "framer-motion-3d";

import * as THREE from "three";

import { ringObj } from "./models/ring.js";

const ring = new URL(ringObj);

const Shape = (props) => {
  const {
    index,
    position,
    color,
    opacity,
    opacityAnimBase,
    opacityAnimSpeed,
    wireframe,
    scale,
    edges,
    spin,
    accord,
    artworkState,
    materialMods,
    perc3
  } = props;

  const ref = useRef();
  const materialRef = useRef();
  const lightRef1 = useRef();
  const lightRef2 = useRef();
  const lightRef3 = useRef();
  const lightRef4 = useRef();

    const ringX = useLoader(OBJLoader, ring);
    const geom = ringX.children[0].geometry;
    const [colorVec] = useState(() => new THREE.Color());

    useFrame(({ clock }) => {
        if (ref?.current && materialRef?.current) {
            if (artworkState !== "dormant") {
                ref.current.rotation.x += spin;
                materialRef.current.opacity = 0.5 * (opacityAnimBase + Math.sin(clock.elapsedTime * opacityAnimSpeed));
                materialRef.current.color.lerp(new THREE.Color(color), 0.02);
                materialRef.current.emissive.lerp(new THREE.Color(color), 0.02);
                materialRef.current.attenuationColor.lerp(new THREE.Color(color), 0.02);
                materialRef.current.sheenColor.lerp(new THREE.Color(color), 0.02);
            } else {
                materialRef.current.color.lerp(new THREE.Color('grey'), 0.02);
                materialRef.current.emissive.lerp(new THREE.Color('grey'), 0.02);
                materialRef.current.attenuationColor.lerp(new THREE.Color('grey'), 0.02);
                materialRef.current.sheenColor.lerp(new THREE.Color('grey'), 0.02);
            }
        }

        if (
            lightRef1?.current &&
            lightRef2?.current &&
            lightRef3?.current &&
            lightRef4?.current
        ) {
            if (artworkState === "dormant") {
                lightRef1.current.intensity = 0;
                lightRef2.current.intensity = 0;
                lightRef3.current.intensity = 0;
                lightRef4.current.intensity = 0;
            } else {
                lightRef1.current.intensity = Math.sin(clock.elapsedTime * (perc3 * 0.1)) * 5.5;
                lightRef2.current.intensity = Math.sin(clock.elapsedTime * (perc3 * 0.1)) * 5.5;
                lightRef3.current.intensity = Math.sin(clock.elapsedTime * (perc3 * 0.1)) * 5.5;
                lightRef4.current.intensity = Math.sin(clock.elapsedTime * (perc3 * 0.1)) * 5.5;
            }
        }
    });

    const variants = {
        normal: {
            x: 0,
            scale: 1,
            transition: {
                duration: 1,
                type: "spring",
                ease: "easeInOut",
                delay: index * 0.5,
            },
        },
        dormant: {
            scale: index === 3 ? 1 : 0,
            transition: {
                duration: 1.3,
                ease: "easeInOut",
            },
        },
        accordion: {
            x: [0, accord, -accord * 0.1, 0],
            transition: {
                duration: 1.3,
                ease: "easeInOut",
            }
        },
    };

    return (
        <motion.group
            position={position}
            rotation={[0, Math.PI * 0.5, 0]}
            initial={{scale: 0}}
            animate={{
                scale: scale,
                transition: {
                    duration: 0.5,
                    ease: 'easeInOut'
                }
            }}
        >
            <motion.mesh
                ref={ref}
                geometry={geom}
                animate={artworkState}
                variants={variants}
            >
                <motion.meshPhysicalMaterial
                    ref={materialRef}
                    metalness={0}
                    transparent
                    transmission={0.6}
                    ior={2}
                    opacity={1}
                    emissiveIntensity={1}
                    clearcoat={1}
                    clearcoatRoughness={0.1}
                    roughness={0.1}
                    sheen={true}
                    thickness={0.2}
                    wireframe={false}
                    side={THREE.DoubleSide}
                />
                {index === 1 &&
                    <group>
                        <pointLight
                            ref={lightRef1}
                            position={[0, 4.5, 0]}
                            intensity={2}
                            color={"white"}
                            distance={8}
                        />
                        <pointLight
                            ref={lightRef2}
                            position={[0, -4.5, 0]}
                            intensity={2}
                            color={"white"}
                            distance={8}
                        />
                        <pointLight
                            ref={lightRef3}
                            position={[0, 0, 4]}
                            intensity={2}
                            color={"white"}
                            distance={8}
                        />
                        <pointLight
                            ref={lightRef4}
                            position={[0, 0, -4]}
                            intensity={2}
                            color={"white"}
                            distance={8}
                        />
                    </group>
                }
            </motion.mesh>
        </motion.group>
    );
};

export default Shape;
