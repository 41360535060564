import React, { useState, useEffect, useContext } from "react";
import Wisdom from "wisdoms_lib_2/src/lib/components/Wisdom";
import { BreakpointContext, VesselContext } from "pages/_app";
import { VesselMetadata, VesselState } from "types/vessels";
import { useRouter } from "next/router";
import allVesselsMetadata from "metadata/vessels.json";

type ArtworkProps = {
    vessel?: VesselMetadata;
    vesselState?: VesselState;
    currIndex?: number;
    hideObjet?: boolean;
};

export const ArtworkContainer: React.FC<ArtworkProps> = () => {
    const [customPosition, setCustomPosition] = useState([0, 0, -2]);
    const { currentVessel, vesselState, setVesselState, setCurrentVessel } =
        useContext(VesselContext);
    const [currIndex, setCurrIndex] = useState(0);
    const breakpoint = useContext(BreakpointContext);

    const router = useRouter();

    useEffect(() => {
        if (
            router.pathname.startsWith("/seeds") ||
            router.pathname === "/vessels"
        ) {
            setVesselState((state: VesselState) => ({
                ...state,
                hideObjet: true,
                hideGui: true,
                state: "normal",
            }));
        } else if (router.pathname.startsWith("/mint")) {
            setVesselState((state: VesselState) => ({
                ...state,
                hideObjet: false,
                hideGui: true,
                state: "normal",
            }));
        } else if (router.pathname === "/") {
            setVesselState((state: VesselState) => ({
                ...state,
                hideObjet: false,
                hideGui: true,
                state: "normal",
            }));
        } else if (router.pathname === "/vessels/[tokenId]") {
            setVesselState((state: VesselState) => ({
                ...state,
                hideObjet: false,
                hideGui: false,
                state: "normal",
            }));
        } else if (router.pathname.startsWith("/marketplace")) {
            setVesselState((state: VesselState) => ({
                ...state,
                hideObjet: true,
                hideGui: true,
                state: "normal",
            }));
        } else {
            setVesselState((state: VesselState) => ({
                ...state,
                hideObjet: false,
                hideGui: false,
                state: "normal",
            }));
        }
    }, [router.pathname]);
    const setValue = (value: number) => {
        setVesselState((state: VesselState) => ({ ...state, value }));
    };

    // function cycleArray() {
    //     if (currIndex === allVesselsMetadata.length - 1) {
    //         setCurrIndex(0);
    //     } else {
    //         setCurrIndex(currIndex + 1);
    //         setCurrentVessel(allVesselsMetadata[currIndex]);
    //     }
    // }

    // setTimeout(cycleArray, 2000);

    useEffect(() => {
        if (breakpoint >= 3) {
            setCustomPosition([-5, 0, -2]);
        } else setCustomPosition([0, 0, -2]);
    }, [breakpoint]);

    return (
        <div className="h-full w-screen pointer-events-none fixed">
            <Wisdom
                wisdom={
                    currentVessel?.wisdom || "Better to journey than to arrive"
                }
                mode={"main"}
                artworkState={vesselState?.state || "normal"}
                hideObjet={vesselState?.hideObjet || false}
                hideGui={vesselState?.hideGui || false}
                customPosition={customPosition}
                value={vesselState?.value || 0}
                setValue={setValue}
            />
        </div>
    );
};
