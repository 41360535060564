import { Header } from "components/Header";
import { ArtworkContainer } from "components/vessels/ArtworkContainer";
import { useContext, useState, useEffect } from "react";
import { LoadingContext } from "pages/_app";
import { useRouter } from "next/router";
import { NextSeo } from "next-seo";
import { Inter } from "next/font/google";

const inter = Inter({
    subsets: ["latin"],
    display: "swap",
});

export default function Layout({ children }: any) {
    const { loading } = useContext(LoadingContext);
    const [showArtwork, setShowArtwork] = useState(false);
    const router = useRouter();

    useEffect(() => {
        if (
            router.pathname.startsWith("/marketplace") ||
            router.pathname === "/vessels" ||
            router.pathname.startsWith("/seeds")
        ) {
            return setShowArtwork(false);
        } else return setShowArtwork(true);
    }, [router.pathname]);

    return (
        <>
            <NextSeo
                title={"Wisdoms for Neknel"}
                description={
                    "Wisdom Vessels → 1,111 dynamic, webGL based, generative, interactive NFTs + all producers of $WISDOM ERC20 by artist collective Keiken."
                }
                openGraph={{
                    url: `https://neknel.world`,
                    title: "Wisdoms for Neknel",
                    description:
                        "Wisdom Vessels → 1,111 dynamic, webGL based, generative, interactive NFTs + all producers of $WISDOM ERC20 by artist collective Keiken.",
                    images: [
                        {
                            url: "https://res.cloudinary.com/daata-prod/image/upload/v1685026511/1500x500_bxsmh2.jpg",
                            width: 800,
                            height: 800,
                            alt: "Og Image Alt",
                            type: "image/png",
                        },
                    ],
                    siteName: "Wisdoms For Neknel",
                }}
                twitter={{
                    handle: "@neknelworld",
                    site: "@neknelworld",
                    cardType: "summary_large_image",
                }}

                // url={`https://wisdoms-react-git-wallet-connect-daata.vercel.app${router.asPath}`}
            />

            <main
                className={`${inter.className}  bg-none ${
                    !showArtwork
                        ? "min-h-screen h-full w-screen bg-[#e3e3e3]"
                        : ""
                }`}
            >
                <div className="flex md:container md:mx-auto md:px-0 relative w-full">
                    <Header />
                    {showArtwork && (
                        <div className="fixed inset-0 bg-black -z-10">
                            <ArtworkContainer />
                        </div>
                    )}
                    {loading && (
                        <div className="fixed inset-0 bg-black bg-opacity-50 z-50"></div>
                    )}
                    {children}
                </div>
            </main>
        </>
    );
}
