import React, { useState, useEffect, useRef } from "react";
import Visual from "./Visual";
import harryStyles from "./WisdomMain.module.css";

const WisdomMain = (props) => {
  const {
    wisdom,
    value,
    perc1,
    perc2,
    perc3,
    perc4,
    modifiers,
    baseWisdomModifiers,
    artworkState,
    setArtworkState,
    animationInProgress,
    handleEndOfAnimation,
    loadedCallback,
    setValue,
    customPosition,
    bindRecord,
  } = props;

  const mainRef = useRef();

  // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

  const [visualSize, setVisualSize] = useState(null);
  const [resizeTimeout, setResizeTimeout] = useState(null);
  const [animate, setAnimate] = useState(null);

  // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

  const determineAndSetVisualSize = () => {
    if (mainRef?.current) {
      let rect = mainRef.current.getBoundingClientRect();
      setVisualSize(rect.height);
    }
  };

  const handleResize = () => {
    clearTimeout(resizeTimeout);

    let timeout = setTimeout(determineAndSetVisualSize, 100);

    setResizeTimeout(timeout);
  };

  const triggerAnimation = (which) => {
    setAnimate(which);
  };

  const animationCompleteCallback = () => {
    if (animate === null) return;
    handleEndOfAnimation();
    setAnimate(null);
    handleEndOfAnimation();
    setAnimate(null);
  };

  // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

  useEffect(() => {
    determineAndSetVisualSize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (artworkState === "dormant") {
      triggerAnimation(artworkState);
    }
    if (artworkState && animationInProgress) triggerAnimation(artworkState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [artworkState, animationInProgress]);

  // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

  return (
    <div id={harryStyles["WisdomMain"]} ref={mainRef}>
      <div id={harryStyles["focus"]}></div>

      <Visual
        mainRef={mainRef}
        wisdom={wisdom}
        size={visualSize}
        value={value}
        perc1={perc1}
        perc2={perc2}
        perc3={perc3}
        perc4={perc4}
        modifiers={modifiers}
        baseModifiers={baseWisdomModifiers}
        loadedCallback={loadedCallback}
        artworkState={artworkState}
        setArtworkState={setArtworkState}
        animationCompleteCallback={animationCompleteCallback}
        setValue={setValue}
        customPosition={customPosition}
        bindRecord={bindRecord}
      />
    </div>
  );
};

export default WisdomMain;
