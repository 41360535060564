import React, { useState, useEffect, useRef, useContext } from "react";

import { Canvas, useFrame, useThree } from "@react-three/fiber";
import GUI from "./GUI";
import { Html } from "@react-three/drei";

import {
  OrbitControls,
  CameraShake,
  Environment,
  AdaptiveDpr,
  AdaptiveEvents,
  PerformanceMonitor,
} from "@react-three/drei";

import * as THREE from "three";

import { Clouds } from "./elements/Clouds";
import Background from "./elements/Background";
import Objet from "./elements/Objet";
import Particles from "./elements/Particles";
import Effects from "./elements/Effects";

import harryStyles from "./Visual.module.css";
import "./Visual.module.css";
import { nx, ny, nz, px, py, pz } from "./cube/cubeMap.js";
import { LoadingIcon } from "./LoadingIcon.js";
import { ColorContext, HideObjetContext } from "./Wisdom";

const Visual = (props) => {
  const {
    mainRef,
    wisdom,
    size,
    value,
    perc1,
    perc2,
    perc3,
    perc4,
    modifiers,
    baseModifiers,
    loadedCallback,
    artworkState,
    setArtworkState,
    animationCompleteCallback,
    setValue,
    customPosition,
    bindRecord,
  } = props;

  const colorValues = useContext(ColorContext);
  const { objetIsHidden, hideGui, shouldFloat } = useContext(HideObjetContext);

  const { hsl3, hsl2 } = colorValues;

  const [dpr, setDpr] = useState(1.5);
  const [canvasPresent, setCanvasPresent] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState({
    active: !objetIsHidden,
    loaded: 0,
    total: 0,
  });
  const [groupPosition, setGroupPosition] = useState([0, 0, -2]);

  useEffect(() => {
    // console.log("new posish");
    setGroupPosition(customPosition);
  }, [customPosition]);
  useEffect(() => {
    // console.log("new posish");
    setGroupPosition(customPosition);
  }, [customPosition]);

  useEffect(() => {
    let timeout = setTimeout(() => setCanvasPresent(true), 400);
    return () => clearTimeout(timeout);
  }, []);
  useEffect(() => {
    let timeout = setTimeout(() => setCanvasPresent(true), 400);
    return () => clearTimeout(timeout);
  }, []);

  // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --
  // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

  if (!modifiers || !size) return null;
  return (
    <div id={harryStyles["visual"]}>
      <div
        id={harryStyles["visual-loading"]}
        className={
          !loadingStatus?.active || objetIsHidden
            ? harryStyles["loading-complete"]
            : null
        }
      >
        <p id={harryStyles["loader-txt"]}></p>
      </div>

      {canvasPresent && (
        <Canvas
          className={harryStyles["visual-canvas"]}
          shadows
          dpr={dpr}
          camera={{ position: [shouldFloat ? 5 : 0, 0, 20], fov: 45 }}
          gl={{ preserveDrawingBuffer: true }}
          onCreated={bindRecord}
        >
          <color attach="background" args={["#000"]} />
          <PerformanceMonitor
            bounds={(refreshrate) => (refreshrate > 90 ? [50, 90] : [50, 60])}
            onIncline={() => setDpr(1.5)}
            onDecline={() => setDpr(1)}
            // onChange={({fps}) => console.log(fps)}
          >
            <group position={groupPosition}>
              {!objetIsHidden && !hideGui && (
                <Html>
                  <GUI
                    wisdom={wisdom}
                    value={value}
                    divisibleBy={10}
                    rootRef={mainRef}
                    visibleTimeout={8000}
                  />
                </Html>
              )}
              {!objetIsHidden && (
                <Objet
                  returnProgress={loadingStatus.active}
                  setLoadingStatus={setLoadingStatus}
                  middleTorus={perc4 % 2 === 0 ? false : true}
                  curve={perc3 % 2 === 0 ? false : true}
                  perc3={perc3}
                  artworkState={artworkState}
                  setArtworkState={setArtworkState}
                  baseModifiers={baseModifiers}
                  modifiers={modifiers}
                  animationCompleteCallback={animationCompleteCallback}
                  setValue={setValue}
                />
              )}
            </group>

            <Particles
              count={value * 10}
              color1={hsl3.getRGB(new THREE.Color())}
              color2={hsl2.getRGB(new THREE.Color())}
              artworkState={artworkState}
              setValue={setValue}
              particleSpeed={
                baseModifiers.particle_speed * (modifiers ? modifiers.psMod : 1)
              }
            />

            {dpr > 1 && (
              <>
                <Effects />
                <Clouds color={hsl3} artworkState={artworkState} />
              </>
            )}

            {shouldFloat && (
              <CameraShake maxRoll={0.08} maxPitch={0.08} maxYaw={0.08} />
            )}

            <Environment files={[nx, ny, nz, px, py, pz]} blur={0.5} />

            <OrbitControls
              makeDefault
              enablePan={false}
              enableZoom={false}
              minDistance={8}
              maxDistance={25}
              minPolarAngle={Math.PI * 0.35}
              maxPolarAngle={Math.PI * 0.65}
            />

            <Background
              color1={hsl3.getRGB(new THREE.Color())}
              color2={hsl2.getRGB(new THREE.Color())}
              artworkState={artworkState}
            />
          </PerformanceMonitor>
        </Canvas>
      )}
    </div>
  );
};

export default Visual;
