import { ConnectButton } from "@rainbow-me/rainbowkit";
import { RoundedBox } from "components/RoundedBox";
import { twMerge } from "tailwind-merge";

type CustomConnectButtonProps = {
    customStyle?: string;
    isInHeader?: boolean;
};

export const CustomConnectButton: React.FC<CustomConnectButtonProps> = ({
    customStyle,
    isInHeader,
}) => {
    return (
        <ConnectButton.Custom>
            {({
                account,
                chain,
                openAccountModal,
                openChainModal,
                openConnectModal,
                authenticationStatus,
                mounted,
            }) => {
                const ready = mounted && authenticationStatus !== "loading";
                const connected =
                    ready &&
                    account &&
                    chain &&
                    (!authenticationStatus ||
                        authenticationStatus === "authenticated");

                return (
                    <div
                        {...(!ready && {
                            "aria-hidden": true,
                            style: {
                                opacity: 0,
                                pointerEvents: "none",
                                userSelect: "none",
                            },
                        })}
                    >
                        {(() => {
                            if (!connected) {
                                if (isInHeader) {
                                    return (
                                        <div onClick={openConnectModal}>
                                            <RoundedBox
                                                customStyle={customStyle}
                                            >
                                                <button
                                                    className="w-full h-full"
                                                    type="button"
                                                >
                                                    Connect
                                                </button>
                                            </RoundedBox>
                                        </div>
                                    );
                                } else
                                    return (
                                        <button
                                            className={twMerge(
                                                "w-full h-full cursor-pointer border bg-white bg-opacity-50",
                                                customStyle
                                            )}
                                            onClick={openConnectModal}
                                            type="button"
                                        >
                                            Connect
                                        </button>
                                    );
                            }

                            if (chain.unsupported) {
                                return (
                                    <RoundedBox
                                        customStyle={twMerge(
                                            customStyle,
                                            "cursor-pointer px-2 py-2 h-10"
                                        )}
                                    >
                                        <button
                                            onClick={openChainModal}
                                            type="button"
                                            className="h-full"
                                        >
                                            <div className="items-center justify-center flex h-full">
                                                Wrong network
                                            </div>
                                        </button>
                                    </RoundedBox>
                                );
                            }

                            return (
                                <RoundedBox
                                    customStyle={twMerge(
                                        customStyle,
                                        "cursor-pointer py-2"
                                    )}
                                >
                                    <button
                                        onClick={openAccountModal}
                                        type="button"
                                        className="whitespace-nowrap h-full w-full"
                                    >
                                        {account.displayName}
                                    </button>
                                </RoundedBox>
                            );
                        })()}
                    </div>
                );
            }}
        </ConnectButton.Custom>
    );
};
