import { useEffect, useContext, useState } from "react";
import { useBalance, useAccount } from "wagmi";

import { BalanceContext } from "pages/_app";
export const BalanceIndicator: React.FC = () => {
    const { balance } = useContext(BalanceContext);
    const [initBalance, setInitBalance] = useState(0);
    const { address } = useAccount();

    const bal = useBalance({
        token: process.env.NEXT_PUBLIC_CURRENCY_ADDRESS as `0x${string}`,
        address: address,
    });

    const etherscanLink = `https://${
        process.env.NEXT_PUBLIC_ENABLE_TESTNETS === "true" ? "goerli." : ""
    }etherscan.io/address/${process.env.NEXT_PUBLIC_CURRENCY_ADDRESS}`;

    useEffect(() => {
        const { data } = bal;
        const _initBalance = data?.formatted ? Number(data?.formatted) : 0;
        return setInitBalance(_initBalance);
    }, [bal]);

    useEffect(() => {
        bal.refetch();
    }, [address]);

    return (
        <div className="py-2 px-4 cursor-pointer flex flex-row gap-2 items-center rounded-3xl p-8 bg-opacity-50 backdrop-blur-md bg-white">
            <img
                src="/images/currency.svg"
                className="w-5 h-5 aspect-square inline"
                alt={"A wisdom coin"}
            />
            <a
                href={etherscanLink}
                target="_blank"
                rel="noreferrer"
                className="cursor-pointer hover:underline"
            >
                <p>
                    {balance
                        ? +(balance + initBalance).toFixed(2)
                        : initBalance.toFixed(2)}
                </p>
            </a>
        </div>
    );
};
