import React, { useState, useEffect } from "react";

import {
    Float
} from "@react-three/drei";

import Objet from "./elements/Objet";

const WisdomUniverse = (props) => {
    const {
        modifiers,
        baseWisdomModifiers,
        position,
        loadedCallback
    } = props;

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    // TODO: do we need to listen to loading in universe mode
    const [loadingStatus, setLoadingStatus] = useState({
        active: true,
        loaded: 0,
        total: 0,
    });

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    useEffect(() => {
        if (!loadingStatus.active) loadedCallback();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingStatus]);

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    return (
        <Float
            speed={
                baseWisdomModifiers.float_speed *
                (modifiers ? modifiers.fsMod : 1)
            }
            rotationIntensity={
                baseWisdomModifiers.float_rotation_intensity *
                (modifiers ? modifiers.friMod : 1)
            }
            floatIntensity={
                baseWisdomModifiers.float_intensity *
                (modifiers ? modifiers.fiMod : 1)
            }
            floatingRange={[
                baseWisdomModifiers.float_range_start *
                    (modifiers ? modifiers.frMod : 1),
                baseWisdomModifiers.float_range_end *
                    (modifiers ? modifiers.frMod : 1),
            ]}
        >
            <group position={position || [0, 0, -6]}>
                <Objet
                    mode="universe"
                    returnProgress={loadingStatus.active}
                    setLoadingStatus={setLoadingStatus}
                />
            </group>
        </Float>
    );
};

export default WisdomUniverse;
