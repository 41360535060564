import { useRef, useContext, useEffect } from 'react';

import { useThree, useFrame, extend } from '@react-three/fiber';

import { shaderMaterial } from '@react-three/drei';

import * as THREE from "three";

import { ColorContext } from "../Wisdom";


const Background = (props) => {

    const {
        color1,
        color2,
        artworkState
    } = props;

    const CreationMaterial = shaderMaterial({
        time: 0,
        color1: new THREE.Color(color2),
        color2: new THREE.Color(color1),
        ratio: window.innerWidth / window.innerHeight,
        pos: 0.2
    },
        `varying vec2 vUv;
    void main(){
        vUv = uv;
        gl_Position = vec4(position, 1.);
    }`,
        `varying vec2 vUv;
    uniform vec3 color1;
    uniform vec3 color2;
    uniform float ratio;
    uniform float pos;
    void main(){
        vec2 uv = (vUv - pos) * vec2(ratio, 1.8);
        gl_FragColor = vec4( mix( color1, color2, length(uv)), 1.5 );
    }`
    );

    extend({ CreationMaterial });

    const mesh = useRef();
    const materialRef = useRef();

    const { size } = useThree();

    useFrame(({ clock }) => {
        if (artworkState !== 'dormant') {
            materialRef.current.uniforms.color1.value.lerp(new THREE.Color(color2), 0.02);
            materialRef.current.uniforms.color2.value.lerp(new THREE.Color(color1), 0.02);
        } else {
            materialRef.current.uniforms.color1.value.lerp(new THREE.Color('grey'), 0.02);
            materialRef.current.uniforms.color2.value.lerp(new THREE.Color('black'), 0.02);
        }
        materialRef.current.uniforms.pos.value = 0.4 + (Math.sin(clock.elapsedTime * 0.2) * 0.2);
        // console.log(materialRef.current.uniforms.pos.value);
    });

    return (
        <mesh
            ref={mesh}
            position={[0, 0, 0]}
        >
            <planeGeometry args={[2, 2]} />
            {/* <planeBufferGeometry args={[size.width, size.height]} /> */}
            <creationMaterial
                ref={materialRef}
                resolution={[size.width, size.height, 1]}
                depthTest={false}
                depthWrite={false}
                luminosity={0.9}
            />
        </mesh>
    )

};

export default Background;