import * as THREE from "three";
import * as Color from "color";
import sha3 from "js-sha3";
import sha1 from "crypto-js/sha1";

// convert random int value into modifier value based on mod sway
const convertSineToModSway = (value, sway) =>
  1 + (Math.sin(value) * (sway * 10)) / 10;

const generateAndReturnRGBVals = (val1, val2) => {
  if (!val1 && !val2) {
    val1 = 0.2;
  }

  let big = val1 >= val2 ? val1 : val2;
  let small = big === val1 ? val2 : val1;
  let perc = small / big;

  let val = 255 * perc;
  return Math.floor(val);
};

const stringToHue = (string) => {
  // Taking a a string, hashing it, and converting it to a number between 0 and 360
  const hash = sha3.keccak_256(string);
  const hashAsNumber = BigInt("0x" + hash);
  // normalizing the 32 byte hashAsNumber to a number between 0 and 360
  const hue = Number(hashAsNumber % BigInt(360));
  return hue;
};

export function generateColorsFromWisdomString(wisdomString) {
  const hue = stringToHue(wisdomString);
  const hue2 = stringToHue(wisdomString + "1");
  const hue3 = stringToHue(wisdomString + "2");

  let hsl1 = Color.hsl(hue, 0.8, 0.8);
  let hsl2 = hsl1.rotate(hue, 0.8, 0.8);
  let hsl3 = hsl1.rotate(hue3, 0.8, 0.8);

  hsl1 = hsl1.object();
  hsl2 = hsl2.object();
  hsl3 = hsl3.object();

  hsl1 = new THREE.Color(`hsl(${hue}, 90%, 35%)`);
  hsl2 = new THREE.Color(`hsl(${hsl2.h}, 90%, 35%)`);
  hsl3 = new THREE.Color(`hsl(${hsl3.h}, 90%, 35%)`);

  return {
    hsl1,
    hsl2,
    hsl3,
  };
}

export function extrapolateAndReturnValuesFromString(stringToUse) {
  let hashToUse = sha1(stringToUse).toString();
  // only int values from string
  let iV = hashToUse.replace(/\D/g, "");
  // only char values from string
  let cS = hashToUse.replace(/\d/g, "");

  // creates a string of integers based on the char values of the original string
  let cV = 0;
  for (let i = 0; i < cS.length; i++) {
    cV += cS.charCodeAt(i);
  }
  cV += "";

  // if no number values were in the string > overwrite this value
  if (!iV) iV = parseInt(cV) * 3.7 + 0.69 + 0.69 + "";

  // determine long and short values
  let l = iV.length >= cV.length ? iV : cV;
  let s = l === iV ? cV : iV;
  l = l.slice(0, s.length);

  // parse long and short values to integers
  let lI = parseInt(l);
  let sI = parseInt(s);

  // determine high and low values
  let h = lI >= sI ? lI : sI;
  let lo = h === lI ? sI : lI;

  return {
    iV,
    cS,
    cV,
    l,
    s,
    lI,
    sI,
    h,
    lo,
  };
}

export function generateAndReturnWisdomVariables(stringToUse, valuesToUse) {
  if (!stringToUse) return null;

  try {
    const { iV, cS, cV, l, s, lI, sI, h, lo } = valuesToUse;

    // determine percentile values
    let perc1 = parseInt(s.slice(0, 2)) / 2;
    let perc2 = parseInt(l.slice(0, 2)) / 2;
    let perc3 = parseInt(l.slice(0, 2));
    let perc4 = parseInt(s.slice(0, 2));

    // determine first rgba value
    let r1 = generateAndReturnRGBVals(
      parseInt(s.slice(0, 1)),
      parseInt(l.slice(l.length - 2, l.length - 1))
    );
    let g1 = generateAndReturnRGBVals(
      parseInt(s.slice(1, 2)),
      parseInt(l.slice(l.length - 1, l.length))
    );
    let b1 = generateAndReturnRGBVals(
      parseInt(l.slice(0, 1)),
      parseInt(s.slice(1, 2))
    );
    let a1 =
      Math.sin(parseInt(s)) >= 0
        ? Math.sin(parseInt(s))
        : Math.sin(parseInt(s)) * -1;

    // determine second rgb value
    let r2 = generateAndReturnRGBVals(
      parseInt(l.slice(1, 2)),
      parseInt(s.slice(s.length - 1, s.length))
    );
    let g2 = generateAndReturnRGBVals(
      parseInt(s.slice(s.length - 2, s.length - 1)),
      parseInt(l.slice(l.length - 2, l.length - 1))
    );
    let b2 = generateAndReturnRGBVals(
      parseInt(l.slice(0, 1)),
      parseInt(s.slice(0, 1))
    );

    // generate the modifiers for the base values
    let sMod = convertSineToModSway(parseInt(iV) - 100, 0.01);
    let ssMod = convertSineToModSway(g1, 0.1);
    let soMod = convertSineToModSway(b1 * 2, 0.4);
    let fiMod = convertSineToModSway(sI + 4.2, 0.9);
    let frMod = convertSineToModSway(parseInt(iV) / 0.69, 0.1);
    let friMod = convertSineToModSway((lI + lo) * 1337, 0.9);
    let fsMod = convertSineToModSway(r1 + b1, 0.8);
    let psMod = convertSineToModSway(g1 * 1337 + b1 * 3, 0.6);
    let ryMod = 0.25 + 0.1 * Math.sin(perc1 + lI / 2);
    let cnlMod = convertSineToModSway(sI + 69 + g1, 0.7);
    let cirMod = convertSineToModSway(sI + 1337, 0.3);
    let corMod = convertSineToModSway((lI * 420) / sI, 0.01);
    let cssMod = convertSineToModSway(lI + sI, 0.75);
    let crsMod = convertSineToModSway(lI * sI, 0.3);
    let nMod = Math.sin(g2 * 4 + b1 - b2 * 1.3) * 0.05 + 0.05;

    let objToRet = {
      perc1,
      perc2,
      perc3,
      perc4,
      r1,
      g1,
      b1,
      a1,
      r2,
      g2,
      b2,

      mods: {
        sMod,
        ssMod,
        soMod,
        fiMod,
        frMod,
        friMod,
        fsMod,
        psMod,
        ryMod,
        nMod,
        cnlMod,
        cirMod,
        corMod,
        cssMod,
        crsMod,
      },
    };

    return objToRet;
  } catch (error) {
    console.error("error caught @ generateAndReturnWisdomVariables:", error);
  }
}
