import * as THREE from "three";

import React,
{
    useRef,
    useMemo,
    useEffect
} from "react";

import { useFrame } from "@react-three/fiber";

import { motion } from "framer-motion-3d";

export default function Particles(props) {

    const {
        count,
        color1,
        color2,
        particleSpeed,
        artworkState,
        setValue
    } = props;

    const mesh = useRef();
    const material = useRef();
    const light = useRef();

    const dummy = useMemo(() => new THREE.Object3D(), []);
    // Generate some random positions, speed factors and timings
    const particles = useMemo(() => {

        const temp = [];
        for (let i = 0; i <= 1000; i++) {
            const t = Math.random() * 100;
            const factor = 20 + Math.random() * 100;
            const speed = Math.random() / 100;
            const xFactor = -50 + Math.random() * 100;
            const yFactor = -50 + Math.random() * 100;
            const zFactor = -50 + Math.random() * 100;
            temp.push({
                t,
                factor,
                speed,
                xFactor,
                yFactor,
                zFactor,
                mx: 0,
                my: 0,
            });
        }
        return temp;

    }, []);

    // The innards of this hook will run every frame
    useFrame(() => {

        // Run through the randomized data to calculate some movement
        particles.forEach((particle, i) => {
            let { t, factor, speed, xFactor, yFactor, zFactor } = particle;
            // There is no sense or reason to any of this, just messing around with trigonometric functions
            t = particle.t += speed / 2;
            const a = Math.cos(t) + Math.sin(t * 1) / 100;
            const b = Math.sin(t) + Math.cos(t * 2) / 100;
            const s = Math.cos(t);

            // Update the dummy object
            dummy.position.set(
                (particle.mx / 10) * a +
                xFactor +
                Math.cos((t / 10) * factor) +
                (Math.sin(t * 1) * factor) / 10,
                (particle.my / 10) * b +
                yFactor +
                Math.sin((t / 10) * factor) +
                (Math.cos(t * 2) * factor) / 10,
                (particle.my / 10) * b +
                zFactor +
                Math.cos((t / 10) * factor) +
                (Math.sin(t * 3) * factor) / 10
            );
            dummy.scale.set(s, s, s);
            dummy.rotation.set(s * 5, s * 5, s * 5);
            dummy.updateMatrix();
            // And apply the matrix to the instanced item
            mesh.current.setMatrixAt(i, dummy.matrix);
        });
        mesh.current.instanceMatrix.needsUpdate = true;

        material?.current?.color.lerp(new THREE.Color(artworkState === 'dormant' ? 'black' : color2), 0.02);
        material?.current?.emissive.lerp(new THREE.Color(artworkState === 'dormant' ? 'grey' : color1), 0.02);

    });

    const variants = {
        normal: {
            scale: 1,
            transition: {
                duration: 2,
                ease: "easeInOut",
            },
        },
        dormant: {
            scale: 0,
            transition: {
                duration: 2,
                ease: "easeInOut",
            },
        },
        accordion: {
            scale: 1,
            transition: {
                duration: 2,
                type: "spring",
                ease: "easeInOut",
            },
        },
        happy_spin: {
            scale: 1,
            transition: {
                duration: 2,
                type: "spring",
                ease: "easeInOut",
            },
        },
        collect_funds: {
            scale: [1, 1.5, 0, 0, 8, 1],
            transition: {
                duration: 3.5,
                delay: 0.5,
                ease: "easeInOut",
            },
            transitionEnd: () => { console.log('end anim'); setValue(0) }
        },
    };

    const meshVariants = {
        collect_funds: {
            opacity: 0,
            transition: {
                duration: 0.4,
                delay: 2.95,
                ease: "easeInOut",
            }
        },
    }

    return (
        <>
            {/* <pointLight
                ref={light}
                position={[0, 0, 20]}
                distance={40}
                intensity={2}
            /> */}
            <motion.instancedMesh
                ref={mesh}
                args={[null, null, count]}
                initial={{ scale: 0 }}
                animate={artworkState}
                variants={variants}
            >
                <circleGeometry args={[0.5, 32]} />
                <motion.meshStandardMaterial
                    ref={material}
                    animate={artworkState}
                    variants={meshVariants}
                    transparent={true}
                />
            </motion.instancedMesh>
        </>
    );
};
