import "styles/globals.css";
import Layout from "components/layout";

import { useState, useEffect, createContext } from "react";

import { VesselState, VesselMetadata } from "types/vessels";
import allVesselsMetadata from "metadata/vessels.json";
import { Analytics } from "@vercel/analytics/react";

// Wallet connect

import "@rainbow-me/rainbowkit/styles.css";
import type { AppProps } from "next/app";
import {
    RainbowKitProvider,
    getDefaultWallets,
    connectorsForWallets,
} from "@rainbow-me/rainbowkit";
import {
    argentWallet,
    trustWallet,
    ledgerWallet,
    rabbyWallet,
} from "@rainbow-me/rainbowkit/wallets";
import {
    configureChains,
    createClient as createWagmiClient,
    WagmiConfig,
} from "wagmi";
import { infuraProvider } from "@wagmi/core/providers/infura";
import { mainnet, goerli } from "wagmi/chains";

import { useRouter } from "next/router";

const { chains, provider, webSocketProvider } = configureChains(
    [process.env.NEXT_PUBLIC_ENABLE_TESTNETS === "true" ? goerli : mainnet],
    [infuraProvider({ apiKey: process.env.NEXT_PUBLIC_INFURA_KEY || "" })]
);

const projectId = process.env.NEXT_PUBLIC_PROJECTID as string;

const { wallets } = getDefaultWallets({
    appName: "neknel.world",
    projectId,
    chains,
});

const connectors = connectorsForWallets([
    ...wallets,
    {
        groupName: "Other",
        wallets: [
            argentWallet({ projectId, chains }),
            trustWallet({ projectId, chains }),
            ledgerWallet({ projectId, chains }),
            rabbyWallet({ chains }),
        ],
    },
]);

const wagmiClient = createWagmiClient({
    autoConnect: true,
    connectors,
    provider,
    webSocketProvider,
});

export enum Breakpoints {
    none,
    sm,
    md,
    lg,
    xl,
    _2xl,
}

const breakPointDefs: { breakpoint: Breakpoints; w: number }[] = [
    { breakpoint: Breakpoints._2xl, w: 1536 },
    { breakpoint: Breakpoints.xl, w: 1280 },
    { breakpoint: Breakpoints.lg, w: 1024 },
    { breakpoint: Breakpoints.md, w: 768 },
    { breakpoint: Breakpoints.sm, w: 640 },
    { breakpoint: Breakpoints.none, w: 0 },
];

export let BreakpointContext = createContext<number>(0);
export let VesselContext = createContext<any>({} as any);
export const LoadingContext = createContext<any>({} as any);
export const BalanceContext = createContext<any>({} as any);

function MyApp({ Component, pageProps }: AppProps) {
    let [currentVessel, setCurrentVessel] = useState<VesselMetadata>(
        allVesselsMetadata[0]
    );
    let [vesselState, setVesselState] = useState<VesselState>({
        hideObjet: false,
        value: 50,
    });

    const [currentBreakpoint, setCurrentBreakpoint] = useState<number>(0);
    const [balance, setBalance] = useState<number>(0);

    useEffect(() => {
        let breakpoint: any;

        function handleResize() {
            for (const bp of breakPointDefs) {
                if (window.innerWidth > bp.w) {
                    breakpoint = bp.breakpoint;
                    break;
                }
            }

            if (breakpoint !== currentBreakpoint) {
                setCurrentBreakpoint(breakpoint);
            } else return;
        }

        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, [currentBreakpoint]);
    const router = useRouter();
    // Handling loading between page routes

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const handleStart = () => setLoading(true);
        const handleComplete = () => setLoading(false);

        router.events.on("routeChangeStart", handleStart);
        router.events.on("routeChangeComplete", handleComplete);
        router.events.on("routeChangeError", handleComplete);

        return () => {
            router.events.off("routeChangeStart", handleStart);
            router.events.off("routeChangeComplete", handleComplete);
            router.events.off("routeChangeError", handleComplete);
        };
    }, [router.events]);

    return (
        <>
            <BalanceContext.Provider value={{ balance, setBalance }}>
                <WagmiConfig client={wagmiClient}>
                    <RainbowKitProvider chains={chains} modalSize="compact">
                        <VesselContext.Provider
                            value={{
                                currentVessel,
                                setCurrentVessel,
                                vesselState,
                                setVesselState,
                            }}
                        >
                            <BreakpointContext.Provider
                                value={currentBreakpoint}
                            >
                                <LoadingContext.Provider value={{ loading }}>
                                    <Layout>
                                        <Component {...pageProps} />
                                    </Layout>
                                </LoadingContext.Provider>
                            </BreakpointContext.Provider>
                        </VesselContext.Provider>
                    </RainbowKitProvider>
                </WagmiConfig>
            </BalanceContext.Provider>

            <Analytics />
        </>
    );
}

export default MyApp;
