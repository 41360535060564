import React, { useRef, useState } from "react";

import { useFrame, useLoader } from "@react-three/fiber";

import { Edges } from "@react-three/drei";

import { motion } from "framer-motion-3d";

import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";

import * as THREE from "three";

import { coneObj } from "./models/cone.js";

const cone = new URL(coneObj);

const Cone = (props) => {
    const {
        position,
        scale,
        wireframe,
        color,
        opacity,
        edges,
        accord,
        whichAnimation,
        artworkState,
        setArtworkState
    } = props;

    const materialRef = useRef();

    const coneX = useLoader(OBJLoader, cone);
    const geom = coneX.children[0].geometry;

    useFrame(() => {
        if (materialRef?.current) {
            materialRef.current.color.lerp(new THREE.Color(artworkState === 'dormant' ? 'black' : color), 0.02);
        }
    });

    const variants = {
        normal: {
            y: 0,
            scale: 1,
            transition: {
                duration: 1.3,
                type: "spring",
                ease: "easeInOut",
                delay: 0,
            },
        },
        dormant: {
            scale: 0,
            transition: {
                duration: 1.3,
                ease: "easeInOut",
            },
        },
        accordion: {
            y: [0, accord, -accord * 0.1, 0],
            scale: 0.95,
            transition: {
                duration: 1.3,
                ease: "easeInOut",
            },
            transitionEnd: () => {setArtworkState('normal')}
        },
    };

    return (
        <motion.group
            position={position}
            rotation={[Math.PI * 0.5, 0, 0]}
            animate={{
                scale: scale,
                transition: {
                    duration: 0.5,
                    ease: 'easeInOut'
                }
            }}
        >
            <motion.mesh
                geometry={geom}
                animate={whichAnimation}
                variants={variants}
            >
                <motion.meshPhysicalMaterial
                    ref={materialRef}
                    transmission={1}
                    clearcoat={1}
                    sheen={1}
                    transparent
                    opacity={1}
                    wireframe={wireframe}
                    side={THREE.DoubleSide}
                />
            </motion.mesh>
        </motion.group>
    );
};

export default Cone;
