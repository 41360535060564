import { useState, useEffect, createContext } from 'react';

import {
    extrapolateAndReturnValuesFromString,
    generateAndReturnWisdomVariables,
    generateColorsFromWisdomString
} from './WisdomUtils';

import WisdomMain from './WisdomMain';
import WisdomUniverse from './WisdomUniverse';

const shouldFloat = true;

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

// set the base values for the wisdom tokens, these will be affected by modifier values (which are created from the wisdom hash)
const baseWisdomModifiers = {
    // scale
    scale: 0.5,
    scale_speed: 0.1,
    scale_offset: 0.05,
    // float
    float_speed: 1,
    float_rotation_intensity: 1,
    float_intensity: 0,
    float_range_start: -1,
    float_range_end: 1,
    // particles
    particle_speed: 0.0001,
    // cone
    cone_scale: 0.41,
    cone_z_pos: -1.2,
    cone_accord: -1.5,
    // curve
    curve_num_loops: 15,
    curve_inner_radius: 2.25,
    curve_outer_radius: 50,
    curve_spin_speed: 0.0115,
    curve_rock_speed: 1,
    // torus 1
    torus_1_scale: 1.1,
    torus_1_z_pos: 0.5,
    torus_1_spin: -0.005,
    torus_1_accord: -1.5,
    torus_1_opacity_base: 1.4,
    torus_1_opacity_speed: 0.5,
    // torus 2
    torus_2_scale: 0.5,
    torus_2_z_pos: -0.5,
    torus_2_spin: 0.003,
    torus_2_accord: -8,
    torus_2_opacity_base: 1.05,
    torus_2_opacity_speed: 0.6,
    // torus 3
    torus_3_scale: 0.2,
    torus_3_z_pos: -1.15,
    torus_3_spin: -0.002,
    torus_3_accord: -30,
    torus_3_opacity_base: 1.2,
    torus_3_opacity_speed: 0.3,
    // disc
    disc_ring_scale: 0.8,
    disc_ring_spin: 0.01
};

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

export let ColorContext = createContext({});
export let HideObjetContext = createContext({});

// An enum, to set a number value for each breakpoint
const breakpoints = {
    none: 0,
    sm: 1,
    md: 2,
    lg: 3,
    xl: 4,
    '2xl': 5
};

const breakPointDefs = [
    { breakpoint: '2xl', w: 1536 },
    { breakpoint: 'xl', w: 1280 },
    { breakpoint: 'lg', w: 1024 },
    { breakpoint: 'md', w: 768 },
    { breakpoint: 'sm', w: 640 },
    { breakpoint: 'none', w: 0 }
];

export default function Wisdom(props) {
    const {
        artworkState,
        wisdom,
        value,
        mode,
        awS,
        position,
        stateChangeCallback,
        loadedCallback,
        setValue,
        hideObjet,
        customPosition,
        bindRecord,
        hideGui: _hideGui
    } = props;

    // Set these in top level context as an object

    const [perc1, setPerc1] = useState(null);
    const [perc2, setPerc2] = useState(null);
    const [perc3, setPerc3] = useState(null);
    const [perc4, setPerc4] = useState(null);
    const [hsl1, setHsl1] = useState({});
    const [hsl2, setHsl2] = useState({});
    const [hsl3, setHsl3] = useState({});
    const [objetIsHidden, setObjetIsHidden] = useState(false);
    const [hideGui, setHideGui] = useState(false);
    const [values, setValues] = useState(null);
    const [modifiers, setModifiers] = useState(null);
    // If there are interval-based animations happening without interaction, they can be declared here.
    const [currentArtworkState, setCurrentArtworkState] = useState('normal');
    // const [currentArtworkState, setCurrentArtworkState] = useState(artworkState);
    const [stateChangeInProgress, setStateChangeInProgress] = useState(false);

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    const handleStateChangeInProgress = (artworkState, inProgress) => {
        setStateChangeInProgress(inProgress);
        setCurrentArtworkState(artworkState);
        if (stateChangeCallback) {
            stateChangeCallback(artworkState, inProgress);
        }
    };

    const handleStateChange = (artworkState) => {
        handleStateChangeInProgress(artworkState, true);
    };

    useEffect(() => {
        if (artworkState) {
            handleStateChange(artworkState);
        } else {
            handleStateChange(awS);
        }
        if (mode !== 'main' || stateChangeInProgress || !awS) return;
        handleStateChange(awS);
    }, [awS, artworkState]);

    // Select whether the Objet appears, or only the environment is used...

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    useEffect(() => {
        const generatedValues = extrapolateAndReturnValuesFromString(wisdom);
        setValues(generatedValues);

        const valuesToSet = generateAndReturnWisdomVariables(
            wisdom,
            generatedValues
        );

        const colorsToSet = generateColorsFromWisdomString(wisdom);

        setPerc1(valuesToSet.perc1);
        setPerc2(valuesToSet.perc2);
        setPerc3(valuesToSet.perc3);
        setPerc4(valuesToSet.perc4);
        setPerc1(valuesToSet.perc1);
        setPerc2(valuesToSet.perc2);
        setPerc3(valuesToSet.perc3);
        setPerc4(valuesToSet.perc4);

        setHsl1(colorsToSet.hsl1);
        setHsl2(colorsToSet.hsl2);
        setHsl3(colorsToSet.hsl3);
        setHsl1(colorsToSet.hsl1);
        setHsl2(colorsToSet.hsl2);
        setHsl3(colorsToSet.hsl3);

        setModifiers(valuesToSet.mods);
    }, [wisdom]);

    useEffect(() => {
        setObjetIsHidden(hideObjet);
        setHideGui(_hideGui);
    }, [hideObjet, _hideGui]);

    useEffect(() => {
        console.log(
            '%cobso1337.life',
            'color:#BADA55;background-color:#000022;padding:2px 8px;border:1px solid #BADA55;text-shadow:-0.7px 0px white;'
        );
    }, []);

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    if (!mode || !values || !modifiers) return null;
    if (mode === 'main') {
        return (
            <HideObjetContext.Provider
                value={{
                    objetIsHidden,
                    setObjetIsHidden,
                    hideGui,
                    shouldFloat
                }}
            >
                <ColorContext.Provider
                    value={{
                        hsl1,
                        hsl2,
                        hsl3
                    }}
                >
                    <WisdomMain
                        wisdom={wisdom}
                        value={value}
                        perc1={perc1}
                        perc2={perc2}
                        perc3={perc3}
                        perc4={perc4}
                        modifiers={modifiers}
                        baseWisdomModifiers={baseWisdomModifiers}
                        artworkState={currentArtworkState}
                        setArtworkState={setCurrentArtworkState}
                        animationInProgress={stateChangeInProgress}
                        handleEndOfAnimation={() =>
                            // when the timeout set in wisdomMain finish, this runs. (After 3400 for 'dormant')
                            handleStateChangeInProgress('normal', false)
                        }
                        loadedCallback={loadedCallback}
                        setValue={setValue}
                        customPosition={customPosition}
                        bindRecord={bindRecord}
                    />
                </ColorContext.Provider>
            </HideObjetContext.Provider>
        );
    } else if (mode === 'universe') {
        return (
            <WisdomUniverse
                modifiers={modifiers}
                baseWisdomModifiers={baseWisdomModifiers}
                position={position}
                loadedCallback={loadedCallback}
            />
        );
    } else return null;
}
